#social-media-block .text {
  width: 280px;
  margin-left: auto;
  margin-right: auto;
}
#social-media-block .text h3 {
  text-align: center;
  font-size: 17.6px;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-family: 'FranklinMed', Arial, sans-serif;
  color: #282931;
  letter-spacing: 0.03em;
  margin-top: 30px;
}
#social-media-block .buttons {
  margin-left: auto;
  margin-right: auto;
  width: 180px;
  margin-bottom: 20px;
}
#social-media-block .buttons .button {
  border: 2px solid black;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  padding: 12px;
  margin: 5px;
}
#social-media-block .buttons .button:hover {
  background-color: black;
  fill: #ffffff !important;
}
#social-media-block .buttons .button:hover svg path {
  fill: yellow !important;
}
#social-media-block .buttons .button:hover svg:hover {
  background-color: black !important;
}
#social-media-block .buttons .button:hover svg:hover path {
  fill: white !important;
}
#social-media-block .buttons a.popup,
#social-media-block .buttons a.popup:hover {
  border-bottom: 0px solid white !important;
}
