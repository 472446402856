#view #quote-block-wrapper.VideoPageBlock,
#page-edit #quote-block-wrapper.VideoPageBlock,
#view .block-editor-VideoPageBlock,
#page-edit .block-editor-VideoPageBlock {
  background-color: #fff;
  position: relative;
  width: 100vw;
  right: 50% !important;
  left: 50% !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
  padding: 0%;
}
#view #quote-block-wrapper.VideoPageBlock .block.align.center iframe,
#page-edit #quote-block-wrapper.VideoPageBlock .block.align.center iframe,
#view .block-editor-VideoPageBlock .block.align.center iframe,
#page-edit .block-editor-VideoPageBlock .block.align.center iframe {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  transform: none;
}
#view #quote-block-wrapper.VideoPageBlock .block.VideoPageBlock::before,
#page-edit #quote-block-wrapper.VideoPageBlock .block.VideoPageBlock::before,
#view .block-editor-VideoPageBlock .block.VideoPageBlock::before,
#page-edit .block-editor-VideoPageBlock .block.VideoPageBlock::before {
  z-index: 0;
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid {
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 23px;
  margin: 0% !important;
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid {
    flex-direction: row;
    padding-bottom: calc(5.21vw + 10px);
  }
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid {
    padding-top: 5.21vw;
    padding-left: calc(5vw + 8px);
    padding-right: calc(5vw);
    padding-bottom: calc(5.21vw + 20px);
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid {
    margin-top: calc(5.21vw + 1px);
    padding-left: calc(5vw + 7px);
    padding-bottom: calc(5.21vw + 30px);
  }
}
@media (min-width: 1600px) {
}
@media (min-width: 2200px) {
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video {
  width: 100%;
  flex-basis: 100%;
  padding: 0% !important;
}
@media (max-width: 992px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video {
    padding-bottom: 25px !important;
  }
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video .block.video.align,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video .block.video.align,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video .block.video.align,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video .block.video.align {
  margin-bottom: 0px;
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video .video-inner,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video .video-inner,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video .video-inner,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video .video-inner {
  margin: 0% !important;
  width: calc(100% - 8px);
  transform: none !important;
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
  line-height: unset !important;
  flex-basis: 100%;
  width: 100%;
  display: block !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 31px !important;
  padding-right: 30px !important;
  align-self: flex-start;
}
@media (min-width: 550px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
    padding-left: 33px !important;
    padding-right: 33px !important;
  }
}
@media (min-width: 650px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
    padding-left: 41px !important;
    padding-right: 43px !important;
  }
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
    padding-right: 6% !important;
    padding-left: 6% !important;
  }
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
    width: 50%;
    flex-basis: 50%;
    padding-top: 4% !important;
    padding-right: 0% !important;
    padding-left: 0% !important;
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
    padding-top: 1% !important;
  }
}
@media (min-width: 1600px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
    padding-top: 2% !important;
  }
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text.right,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text.right,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text.right,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text.right {
  align-self: flex-end;
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid h2,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid h2,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid h2,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid h2 {
  padding: 0% !important;
  color: #e70518 !important;
  margin-top: 10px !important;
  margin-bottom: 15px !important;
  margin-left: 0px !important;
  margin-right: 10px !important;
  font-family: 'FranklinBook', Arial, sans-serif;
  letter-spacing: 0em;
  text-transform: none;
  font-size: 16px !important;
  line-height: 30px !important;
}
@media (min-width: 650px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid h2,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid h2,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid h2,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid h2 {
    padding-top: 2px !important;
  }
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid h2,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid h2,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid h2,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid h2 {
    font-size: 1.6vw !important;
    margin-top: 8px !important;
    padding-top: 0px !important;
    margin-bottom: 15px !important;
    margin-left: 0px !important;
    line-height: 17.84px !important;
  }
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid h2,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid h2,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid h2,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid h2 {
    margin-top: 0px !important;
    line-height: 1.8vw !important;
    margin-bottom: 37px !important;
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid h2,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid h2,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid h2,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid h2 {
    font-size: 1.5vw !important;
    margin-bottom: 38px !important;
  }
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer {
  padding: 0% !important;
  font-size: 16px !important;
  line-height: 30px !important;
  margin-top: 0px !important;
  margin-bottom: 35px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  font-family: 'FranklinBook', Arial, sans-serif;
  letter-spacing: 0em;
  color: #292831;
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer {
    font-size: 2vw !important;
    line-height: 1.45em !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer {
    font-size: 2vw !important;
    line-height: 1.45em !important;
    margin-top: 8px !important;
    margin-bottom: 33px !important;
  }
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p::after,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p::after,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p::after,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p::after,
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p::after,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p::after,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p::after,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p::after,
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) .public-DraftStyleDefault-block span span::after,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) .public-DraftStyleDefault-block span span::after,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) .public-DraftStyleDefault-block span span::after,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) .public-DraftStyleDefault-block span span::after,
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) .public-DraftStyleDefault-block span span::after,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) .public-DraftStyleDefault-block span span::after,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) .public-DraftStyleDefault-block span span::after,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) .public-DraftStyleDefault-block span span::after {
  content: "\00a0\00a0\00a0\00a0\00a0———";
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p:has(br)::after,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p:has(br)::after,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p:has(br)::after,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p:has(br)::after,
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p:has(br)::after,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p:has(br)::after,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p:has(br)::after,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p:has(br)::after {
  content: "";
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2),
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2),
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2),
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2),
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3),
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3),
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3),
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) {
    padding-top: 2% !important;
  }
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span {
  padding: 0% !important;
  color: #e70518 !important;
  margin-top: 10px !important;
  margin-bottom: 15px !important;
  margin-left: 0px !important;
  margin-right: 10px !important;
  font-family: 'FranklinBook', Arial, sans-serif;
  letter-spacing: 0em;
  text-transform: none;
  font-size: 16px !important;
  line-height: 30px !important;
}
@media (min-width: 650px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span {
    padding-top: 2px !important;
  }
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span {
    font-size: 1.6vw !important;
    margin-top: 8px !important;
    padding-top: 0px !important;
    margin-left: 0px !important;
    line-height: 17.84px !important;
  }
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span {
    margin-top: 5px !important;
    line-height: 1.8vw !important;
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span {
    font-size: 1.5vw !important;
  }
}
#page-edit .block-editor-VideoPageBlock {
  background-color: white;
  width: 90vw !important;
  padding-left: 100px !important;
}
#page-edit .block-editor-VideoPageBlock .block-add-button,
#page-edit .block-editor-VideoPageBlock .remove-block-button,
#page-edit .block-editor-VideoPageBlock .toolbar {
  display: none;
}
.has-sidebar #page-edit .block-editor-VideoPageBlock {
  background-color: white;
}
.has-sidebar #page-edit .block-editor-VideoPageBlock {
  padding-left: 150px !important;
  width: 70vw !important;
  margin-right: -40vw !important;
  margin-left: -40vw !important;
}
@media screen and (max-width: 1150px) {
  .has-sidebar #page-edit .block-editor-VideoPageBlock {
    width: 65vw !important;
  }
  .has-sidebar #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid {
    padding-left: 0px !important;
  }
}
@media screen and (max-width: 1100px) {
  .has-sidebar #page-edit .block-editor-VideoPageBlock {
    padding-left: 120px !important;
    width: 60vw !important;
  }
}
@media screen and (max-width: 1000px) {
  .has-sidebar #page-edit .block-editor-VideoPageBlock {
    padding-left: 50px !important;
  }
}
@media screen and (max-width: 768px) {
  .has-sidebar #page-edit .block-editor-VideoPageBlock {
    padding-left: 0px !important;
  }
}
