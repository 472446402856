#vimeo-block {
  position: relative;
  max-height: 720px;
  width: 100vw;
  overflow: hidden;
  right: 50%;
  left: 50%;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
@media (min-width: 1640px) {
  #vimeo-block {
    max-height: 1000px;
  }
}
#vimeo-block .video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 Aspect Ratio */
  height: 0;
}
#vimeo-block iframe {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 1400px) and (max-width: 1500px) {
  #vimeo-block iframe {
    bottom: 5%;
  }
}
@media screen and (min-width: 1500px) and (max-width: 1640px) {
  #vimeo-block iframe {
    bottom: 10%;
  }
}
@media screen and (min-width: 1900px) and (max-width: 2000px) {
  #vimeo-block iframe {
    bottom: 5%;
  }
}
@media screen and (min-width: 2000px) and (max-width: 2200px) {
  #vimeo-block iframe {
    bottom: 10%;
  }
}
@media screen and (min-width: 2200px) and (max-width: 2500px) {
  #vimeo-block iframe {
    bottom: 20%;
  }
}
@media screen and (min-width: 2500px) {
  #vimeo-block iframe {
    bottom: 30%;
  }
}
.view-editview #vimeo-block iframe {
  z-index: -1;
}
#vimeo-block .vimeo-buttons {
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 20px;
  padding-top: 25px;
  padding-bottom: 40px;
}
@media (min-width: 768px) {
  #vimeo-block .vimeo-buttons {
    position: absolute;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
#vimeo-block .vimeo-buttons a.button {
  padding-top: 17px;
  padding-bottom: 12px;
  margin: 10px 20px !important;
  text-transform: uppercase;
  color: #282931;
  width: 228px;
  font-family: 'FranklinMed', Arial, sans-serif;
  letter-spacing: 0.05em;
  font-size: 17px;
  text-align: center;
  margin-left: 38px !important;
  margin-right: 38px !important;
  border: 1px solid #282931 !important;
}
#vimeo-block .vimeo-buttons a.button:hover {
  background-color: #282931 !important;
  border: 1px solid #282931 !important;
  color: #fff;
}
@media (min-width: 768px) {
  #vimeo-block .vimeo-buttons a.button {
    color: #fff;
  }
  #vimeo-block .vimeo-buttons a.button:hover {
    background-color: #282931 !important;
    border: 1px solid #282931 !important;
  }
  #vimeo-block .vimeo-buttons a.button1 {
    background: transparent;
    border: 1px solid #fff !important;
  }
  #vimeo-block .vimeo-buttons a.button2 {
    background: transparent;
    color: #ffffff;
    border: 1px solid #ffffff !important;
  }
}
@media screen and (max-width: 600px) {
  #vimeo-block .vimeo-buttons {
    flex-direction: column !important;
    align-items: center;
    bottom: 270px;
  }
}
@media screen and (max-width: 480px) {
  #vimeo-block .vimeo-buttons {
    bottom: 180px;
  }
}
