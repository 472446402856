#main .ui.basic.segment.content-area main {
  transform: none !important;
}
#view #sliding-block-wrapper.SlidingTextBlock,
#page-edit #sliding-block-wrapper.SlidingTextBlock,
#view .block-editor-SlidingTextBlock,
#page-edit .block-editor-SlidingTextBlock {
  transform: none !important;
  background-color: #fff;
  position: relative;
  display: block;
  width: 100vw;
  right: 50% !important;
  left: 50% !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
  padding: 0% !important;
}
#view #sliding-block-wrapper.SlidingTextBlock .block.SlidingTextBlock::before,
#page-edit #sliding-block-wrapper.SlidingTextBlock .block.SlidingTextBlock::before,
#view .block-editor-SlidingTextBlock .block.SlidingTextBlock::before,
#page-edit .block-editor-SlidingTextBlock .block.SlidingTextBlock::before {
  z-index: 0;
}
#view #sliding-block-wrapper.SlidingTextBlock .background-image,
#page-edit #sliding-block-wrapper.SlidingTextBlock .background-image,
#view .block-editor-SlidingTextBlock .background-image,
#page-edit .block-editor-SlidingTextBlock .background-image {
  transform: none !important;
  padding: 0%;
  -o-object-fit: cover !important;
     object-fit: cover !important;
  position: sticky;
  position: -webkit-sticky;
  --webkit-backface-visibility: hidden;
  --webkit-perspective: 1000;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100vh !important;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (min-width: 1024px) {
  #view #sliding-block-wrapper.SlidingTextBlock .background-image,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .background-image,
  #view .block-editor-SlidingTextBlock .background-image,
  #page-edit .block-editor-SlidingTextBlock .background-image {
    background-attachment: fixed !important;
  }
}
#view #sliding-block-wrapper.SlidingTextBlock .column.grid-block-image,
#page-edit #sliding-block-wrapper.SlidingTextBlock .column.grid-block-image,
#view .block-editor-SlidingTextBlock .column.grid-block-image,
#page-edit .block-editor-SlidingTextBlock .column.grid-block-image,
#view #sliding-block-wrapper.SlidingTextBlock .column.grid-block-undefined,
#page-edit #sliding-block-wrapper.SlidingTextBlock .column.grid-block-undefined,
#view .block-editor-SlidingTextBlock .column.grid-block-undefined,
#page-edit .block-editor-SlidingTextBlock .column.grid-block-undefined {
  width: 100%;
  /* Your styles here */
}
#view #sliding-block-wrapper.SlidingTextBlock .column.grid-block-image img,
#page-edit #sliding-block-wrapper.SlidingTextBlock .column.grid-block-image img,
#view .block-editor-SlidingTextBlock .column.grid-block-image img,
#page-edit .block-editor-SlidingTextBlock .column.grid-block-image img,
#view #sliding-block-wrapper.SlidingTextBlock .column.grid-block-undefined img,
#page-edit #sliding-block-wrapper.SlidingTextBlock .column.grid-block-undefined img,
#view .block-editor-SlidingTextBlock .column.grid-block-undefined img,
#page-edit .block-editor-SlidingTextBlock .column.grid-block-undefined img {
  width: 100px;
}
#view #sliding-block-wrapper.SlidingTextBlock .column.grid-block-image div#photo-credit,
#page-edit #sliding-block-wrapper.SlidingTextBlock .column.grid-block-image div#photo-credit,
#view .block-editor-SlidingTextBlock .column.grid-block-image div#photo-credit,
#page-edit .block-editor-SlidingTextBlock .column.grid-block-image div#photo-credit,
#view #sliding-block-wrapper.SlidingTextBlock .column.grid-block-undefined div#photo-credit,
#page-edit #sliding-block-wrapper.SlidingTextBlock .column.grid-block-undefined div#photo-credit,
#view .block-editor-SlidingTextBlock .column.grid-block-undefined div#photo-credit,
#page-edit .block-editor-SlidingTextBlock .column.grid-block-undefined div#photo-credit {
  color: #fff;
  font-size: 12px;
  letter-spacing: 0;
  display: flex;
}
#view #sliding-block-wrapper.SlidingTextBlock .column.grid-block-image div#photo-credit span,
#page-edit #sliding-block-wrapper.SlidingTextBlock .column.grid-block-image div#photo-credit span,
#view .block-editor-SlidingTextBlock .column.grid-block-image div#photo-credit span,
#page-edit .block-editor-SlidingTextBlock .column.grid-block-image div#photo-credit span,
#view #sliding-block-wrapper.SlidingTextBlock .column.grid-block-undefined div#photo-credit span,
#page-edit #sliding-block-wrapper.SlidingTextBlock .column.grid-block-undefined div#photo-credit span,
#view .block-editor-SlidingTextBlock .column.grid-block-undefined div#photo-credit span,
#page-edit .block-editor-SlidingTextBlock .column.grid-block-undefined div#photo-credit span {
  width: auto;
  color: white;
}
#view #sliding-block-wrapper.SlidingTextBlock .column.grid-block-image div#photo-credit span a,
#page-edit #sliding-block-wrapper.SlidingTextBlock .column.grid-block-image div#photo-credit span a,
#view .block-editor-SlidingTextBlock .column.grid-block-image div#photo-credit span a,
#page-edit .block-editor-SlidingTextBlock .column.grid-block-image div#photo-credit span a,
#view #sliding-block-wrapper.SlidingTextBlock .column.grid-block-undefined div#photo-credit span a,
#page-edit #sliding-block-wrapper.SlidingTextBlock .column.grid-block-undefined div#photo-credit span a,
#view .block-editor-SlidingTextBlock .column.grid-block-undefined div#photo-credit span a,
#page-edit .block-editor-SlidingTextBlock .column.grid-block-undefined div#photo-credit span a {
  color: white;
}
#view #sliding-block-wrapper.SlidingTextBlock .column.grid-block-image div#photo-credit > :nth-child(2),
#page-edit #sliding-block-wrapper.SlidingTextBlock .column.grid-block-image div#photo-credit > :nth-child(2),
#view .block-editor-SlidingTextBlock .column.grid-block-image div#photo-credit > :nth-child(2),
#page-edit .block-editor-SlidingTextBlock .column.grid-block-image div#photo-credit > :nth-child(2),
#view #sliding-block-wrapper.SlidingTextBlock .column.grid-block-undefined div#photo-credit > :nth-child(2),
#page-edit #sliding-block-wrapper.SlidingTextBlock .column.grid-block-undefined div#photo-credit > :nth-child(2),
#view .block-editor-SlidingTextBlock .column.grid-block-undefined div#photo-credit > :nth-child(2),
#page-edit .block-editor-SlidingTextBlock .column.grid-block-undefined div#photo-credit > :nth-child(2) {
  padding-left: 4px;
}
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid {
  transform: none !important;
  flex-direction: column !important;
  justify-content: center;
  height: 100%;
  padding: 0% !important;
}
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image {
  width: 100%;
  padding: 0px 33px 50px 32px !important;
}
@media (min-width: 768px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image {
    padding: 7px 40px !important;
    margin: 0 auto;
    max-width: 82.5%;
  }
}
@media (min-width: 992px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image {
    max-width: 72%;
  }
}
@media (min-width: 1200px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image {
    padding-left: 36px !important;
    padding-bottom: 7px !important;
  }
}
@media (min-width: 1400px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image {
    padding-left: 23px !important;
  }
}
@media (min-width: 1600px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image {
    padding-left: 23px !important;
  }
}
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3),
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3),
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3),
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) {
  padding-bottom: 0px !important;
}
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p::after,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p::after,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p::after,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p::after,
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) .public-DraftStyleDefault-block span span::after,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) .public-DraftStyleDefault-block span span::after,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) .public-DraftStyleDefault-block span span::after,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) .public-DraftStyleDefault-block span span::after {
  content: "\00a0\00a0\00a0\00a0\00a0———";
}
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) .public-DraftEditorPlaceholder-root:after,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) .public-DraftEditorPlaceholder-root:after,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) .public-DraftEditorPlaceholder-root:after,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) .public-DraftEditorPlaceholder-root:after {
  padding: 0% !important;
}
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span {
  padding: 0% !important;
  color: #fff !important;
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  font-family: 'FranklinBook', Arial, sans-serif;
  letter-spacing: 0em;
  text-transform: none;
  font-size: 5.5vw !important;
  margin-bottom: 18px !important;
  line-height: 41.25px !important;
}
@media (min-width: 768px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span {
    font-size: 3vw !important;
    margin-bottom: 16px !important;
  }
}
@media (min-width: 992px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span {
    font-size: 2.1vw !important;
    margin-top: 8px !important;
    line-height: 1.8vw !important;
    margin-bottom: 12px !important;
  }
}
@media (min-width: 1600px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span {
    line-height: 1.4em !important;
    margin-bottom: 10px !important;
  }
}
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-inner,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-inner,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-inner,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-inner {
  padding-top: 25px;
  color: #fff;
}
@media (min-width: 768px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image {
    padding: 0px 40px !important;
    padding-bottom: 150px !important;
  }
}
@media (min-width: 1200px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image {
    padding-top: 0px !important;
    padding-bottom: 160px !important;
  }
}
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column span,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column span,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column span,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column span {
  width: 100%;
}
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid h2,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid h2,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid h2,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid h2 {
  padding: 0% !important;
  color: #fff !important;
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  font-family: 'FranklinBook', Arial, sans-serif;
  letter-spacing: 0em;
  text-transform: none;
  font-size: 5.5vw !important;
  margin-bottom: 18px !important;
  line-height: 41.25px !important;
}
@media (min-width: 768px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid h2,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid h2,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid h2,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid h2 {
    font-size: 3vw !important;
    margin-bottom: 16px !important;
  }
}
@media (min-width: 992px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid h2,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid h2,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid h2,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid h2 {
    font-size: 2.1vw !important;
    margin-top: 8px !important;
    line-height: 1.8vw !important;
    margin-bottom: 18px !important;
  }
}
@media (min-width: 1600px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid h2,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid h2,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid h2,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid h2 {
    line-height: 1.4em !important;
    margin-bottom: 10px !important;
  }
}
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid p,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid p,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid p,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid p,
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer {
  padding: 0% !important;
  font-size: 7.5vw !important;
  line-height: 1.5em !important;
  margin-top: 0px !important;
  margin-bottom: 35px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  font-family: 'FranklinBook', Arial, sans-serif;
  letter-spacing: 0em;
  color: #fff;
}
@media (min-width: 768px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid p,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid p,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid p,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid p,
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer {
    font-size: 4.5vw !important;
    line-height: 1.65em !important;
  }
}
@media (min-width: 992px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid p,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid p,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid p,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid p,
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer {
    font-size: 3.3vw !important;
    line-height: 1.4em !important;
    margin-top: 8px !important;
    margin-bottom: 35px !important;
  }
}
#page-edit .block-editor-SlidingTextBlock {
  background-color: white;
  width: 90vw !important;
  padding-left: 100px !important;
}
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid {
  flex-direction: column-reverse !important;
}
#page-edit .block-editor-SlidingTextBlock .block-add-button,
#page-edit .block-editor-SlidingTextBlock .remove-block-button,
#page-edit .block-editor-SlidingTextBlock .toolbar {
  display: none;
}
.has-sidebar #page-edit .block-editor-SlidingTextBlock .SlidingTextBlock {
  background-color: #c0bfbf;
}
.has-sidebar #page-edit .block-editor-SlidingTextBlock {
  padding-left: 150px !important;
  width: 70vw !important;
  margin-right: -40vw !important;
  margin-left: -40vw !important;
}
@media screen and (max-width: 1150px) {
  .has-sidebar #page-edit .block-editor-SlidingTextBlock {
    width: 65vw !important;
  }
  .has-sidebar #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid {
    padding-left: 0px !important;
  }
}
@media screen and (max-width: 1100px) {
  .has-sidebar #page-edit .block-editor-SlidingTextBlock {
    padding-left: 120px !important;
    width: 60vw !important;
  }
}
@media screen and (max-width: 1000px) {
  .has-sidebar #page-edit .block-editor-SlidingTextBlock {
    padding-left: 50px !important;
  }
}
@media screen and (max-width: 768px) {
  .has-sidebar #page-edit .block-editor-SlidingTextBlock {
    padding-left: 0px !important;
  }
}
